/********************************************************************************/

import * as javascript from "@radically-straightforward/javascript/static/index.mjs";
import * as utilities from "@radically-straightforward/utilities";


/********************************************************************************/

javascript?.execute?.functions?.set?.("ettnrnqkfpckjs", async function(event) {
  setTimeout(() => {
    this.remove();
  }, 3 * 1000);
}
);

/********************************************************************************/

javascript?.execute?.functions?.set?.("ktkbsqudvlopu", async function(event) {
  this.onclick = () => {
    this.select();
  };
}
);

/********************************************************************************/

javascript?.execute?.functions?.set?.("hamrvidzxmzhbd", async function(event, $$0) {
  this.onclick = async () => {
    await navigator.clipboard.writeText($$0);
    javascript
      .tippy({
        element: this,
        trigger: "manual",
        content: "Copied",
      })
      .show();
    await utilities.sleep(1000);
    this.tippy.hide();
  };
}
);

/********************************************************************************/

javascript?.execute?.functions?.set?.("bzjnytfboqurqp", async function(event) {
  this.onvalidate = () => {
    try {
      new URL(this.value);
    } catch {
      throw new javascript.ValidationError("Invalid URL.");
    }
  };
}
);

/********************************************************************************/

javascript?.execute?.functions?.set?.("eawwuwlalcxrlc", async function(event, $$0, $$1) {
  this.onvalidate = () => {
    if (this.value !== $$0) throw new javascript.ValidationError($$1);
  };
}
);

